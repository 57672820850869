import {Controller} from "@hotwired/stimulus"
import {Drawer} from 'flowbite';

export default class extends Controller {
    static targets = ["drawer", "range", "orgPrice", "sellPrice", "shippingPrice"]
    static values = {
        itemSkus: Array
    }
    drawer

    initialize() {
        const options = {
            placement: 'right',
            backdrop: true,
            bodyScrolling: false,
        };
        this.drawer = new Drawer(this.drawerTarget, options);
    }

    connect() {
        this.drawer.show()
        this.updateSku({params: {skuIndex: 0}})
    }

    updateSku({params}) {
        const itemSku = this.itemSkusValue[params.skuIndex]
        this.rangeTarget.innerText = `${itemSku.min_range} ~ ${itemSku.max_range}`;
        this.orgPriceTarget.innerText = itemSku.org_price
        this.sellPriceTarget.innerText = itemSku.withdraw_price
        this.shippingPriceTarget.innerText = itemSku.freight
    }

    closeDrawer() {
        this.drawer.hide()
    }
}
