import {Controller} from "@hotwired/stimulus"
import {Modal} from 'flowbite';

export default class extends Controller {
    static targets = ["errorModal"]
    errorModal

    initialize(error) {
        if (!this.element.dataset.error) return

        const options = {
            backdropClasses:
                'bg-gray-900/70 dark:bg-gray-900/80 fixed inset-0 z-40'
        }
        this.errorModal = new Modal(this.errorModalTarget, options)
    }

    connect() {
        this.errorModal?.show()
    }
}
