import {Controller} from "@hotwired/stimulus"
import EmblaCarousel from 'embla-carousel'
import {random} from "lodash/number";

export default class extends Controller {
    static values = {itemIds: Array}
    static targets = ["opened", "toggleModal", "openedItemImg"]
    emblaApi

    initialize() {
        const emblaNode = document.querySelector('.embla')
        const viewportNode = emblaNode.querySelector('.embla__viewport')
        const options = {loop: true}
        const emblaApi = EmblaCarousel(viewportNode, options)
        this.emblaApi = emblaApi
    }

    openedTargetConnected(element) {
        const elementItemId = Number.parseInt(element.dataset.itemId)
        const openedIndex = this.itemIdsValue.indexOf(elementItemId)
        if (openedIndex === -1) return
        let startIndex = random(this.itemIdsValue.length, false)
        if (openedIndex === startIndex) {
            startIndex += 1
        }
        // this.emblaApi.reInit({startIndex: startIndex})
        let scrollCount = 0
        while (this.emblaApi.selectedScrollSnap() !== openedIndex && scrollCount < 50) {
            this.emblaApi.scrollNext()
            scrollCount++
        }
        const openedItem = this.emblaApi.slideNodes()[this.emblaApi.selectedScrollSnap()].firstElementChild?.cloneNode(true)
        openedItem && openedItem.classList.add('mx-auto')
        this.openedItemImgTarget.replaceChildren(openedItem)
        this.toggleModalTarget.click()
    }
}
