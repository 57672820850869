import { Controller } from "@hotwired/stimulus"
import { camelCase } from "lodash";

export default class extends Controller {
  static targets = [
      "linkDelete", "linkEdit", "linkSku", "name", "brandName", "propName", "lowestOrgPrice", "highestOrgPrice",
      "probability", "description", "image"
  ]

  initialize() {
    this.routePath = this.element.dataset.routePath
  }

  updateLinkDelete(event) {
    this.linkDeleteTarget.href = `${this.routePath}/${event.params.id}`
  }

  initPreview({ params }) {
    this.nameTargets.forEach((el) => {
      el.innerHTML = params.item.name
    })
    for (const [key, val] of Object.entries(params.item)) {
      if (this[camelCase(`has-${key}-Target`)]) {
        this[camelCase(`${key}Target`)].innerHTML = val
      }
    }
    this.linkEditTarget.href = `${this.routePath}/${params.item.id}/edit`
    this.linkSkuTarget.href = `${this.routePath}/${params.item.id}/item_skus`
    this.linkDeleteTarget.href = `${this.routePath}/${params.item.id}`
  }
}
